import React, { useContext, useEffect, useState } from 'react'
import StoreContext from '~/context/StoreContext'
import LineItem from './LineItem'
import styles from './Cart.module.scss'
import Button from '~/components/00-global/Button/Button'
import Caret from '~/images/cart-caret.svg'
import getPrice from '~/components/00-global/util/price'
import IconClose from '~/images/icon-x.svg'
import { motion, useAnimation } from 'framer-motion'
import { navigate, useIntl, FormattedMessage } from 'gatsby-plugin-intl'

const Cart = ({ toggled, handleToggle }) => {
  const intl = useIntl()
  const {
    store: { checkout }
  } = useContext(StoreContext)

  const lineItems = checkout.lineItems.map(item => (
    <LineItem key={item.id.toString()} item={item} />
  ))

  const controls = useAnimation()

  const sequence = async () => {
    if (toggled) {
      return await controls.start({
        opacity: [0, 1, 1],
        y: 0,
        visibility: 'visible'
      })
    } else {
      await controls.start({ opacity: [1, 1, 0], y: -20 })
      return await controls.start({ visibility: 'hidden' })
    }
  }

  // Toggle cart when items are added and not on first render
  useEffect(() => {
    if (checkout.lineItems.length) {
      handleToggle(true)
    }
  }, [checkout.lineItems])

  useEffect(() => {
    sequence()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggled])

  return (
    <motion.div
      className={styles.cart}
      initial={{ visibility: 'hidden' }}
      animate={controls}
      transition={{
        duration: 0.3,
        type: 'spring',
        damping: 13
      }}
    >
      <button
        className={styles.close}
        aria-label={intl.formatMessage({ id: 'cart.untoggleCart' })}
        onClick={() => handleToggle(false)}
      >
        <img src={IconClose} alt='' />
      </button>
      <img className={styles.caret} src={Caret} alt='' />
      {lineItems}
      {lineItems.length !== 0 ? (
        <>
          <hr />
          <div className={styles.price}>
            <p>
              <FormattedMessage id='cart.priceTotal' />
            </p>
            <p>{getPrice(checkout.totalPrice.amount)}</p>
          </div>
          <small>
            <FormattedMessage id='cart.priceInfo' />
          </small>
          <br />
          <div className={styles.buttonsContainer}>
            <div className={styles.buttonContainer}>
              <Button
                asElement='button'
                variant='secondary'
                onClick={() => {
                  handleToggle(false)
                  navigate('/shop')
                }}
              >
                <FormattedMessage id='cart.shopButton' />
              </Button>
            </div>
            <div className={styles.buttonContainer}>
              <Button
                asElement='button'
                onClick={() => window.open(checkout.webUrl)}
                disabled={checkout.lineItems.length === 0}
              >
                <FormattedMessage id='cart.payButton' />
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className={styles.empty}>
          <p>
            <FormattedMessage id='cart.cartIsEmpty' />
          </p>
        </div>
      )}
    </motion.div>
  )
}
export default Cart
